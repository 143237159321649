import React from 'react'
import "./Advantages.scss"

const Advantages = () => {

    const cards = [
        {
            title: "Гарантия 5 лет",
            text: "Гарантийный срок объекта, с момента сдачи в эксплуатацию — 5 лет",
        },
        {
            title: "Современный архитектурный проект",
            text: "Индивидуальный архитектурный проект от бюро «План Б»",
        },
        {
            title: "Проверенный подрядчик",
            text: "Аккредитованный подрядчик АО «БИК»",
        },

    ]


    return (
        <div className='advantages'>
            <div className="container-center">
                <h2 className='mb-32'>Преимущества</h2>
                <div className='advantages-grid'>
                    {cards.map((item, idx) => {
                        return (
                            <div key={idx} className='advantage-card'>
                                <p className='advantage-title'>{item.title}</p>
                                <p className='advantage-text'>{item.text}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Advantages