import React from 'react'
import "./Contacts.scss"
import MyMap from "./Map";
import { YMaps } from '@pbe/react-yandex-maps';



const Contacts = () => {
    return (
        <div className='contacts'>
            <div className='container-center'>
                <h2 className='mb-24'>Контакты</h2>
            </div>
            <div className='yandex-map'>
                <YMaps>
                    <MyMap />
                </YMaps>
                <div className='container-center'>
                    <div className='map-info'>
                        <p className='map-title'>Отдел продаж</p>
                        <p className='map-text'>Белгородская область, Старый Оскол, мкр Солнечный 1А, офис Россельхозбанка</p>
                        <a className='map-phone' href="tel:+79913155535">+7 991 315 55 35</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts