import React from 'react'
import "./BuyingOptions.scss"
import Fond from "../../assets/icons/fond-logo.svg"
import DomRf from "../../assets/icons/domrf-logo.svg"
import Rub from "../../assets/icons/rub.svg"



const BuyingOptions = () => {
  const options = [
    // {
    //   percent: "8,5% до 6 млн ₽",
    //   text: "Займ от Фонда поддержки ИЖС",
    //   img: Fond,
    //   link: "https://bik31.ru/kredity-i-zajmy/zajmy-fonda-izhs-2/"
    // },
    {
      percent: "от 5% до 18 млн ₽",
      text: "Кредит от ДОМ.РФ",
      img: DomRf,
      link: "https://bik31.ru/kredity-i-zajmy/kreditnye-programmy-domrf/"
    },
    {
      percent: "Выгода до 7%",
      text: "Собственные средства",
      condition: "при оплате всей суммы за дом 120м2",
      img: Rub

    }
  ]
  return (
    <div className='buying-options'>
      <div className="container-center">
        <h2 className='mb-32'>Варианты приобретения</h2>
        <div className='buying-options_grid'>
          {options.map((option, idx) => {
            return (
              <a href={option.link} key={idx} className='option-item'>
                <div>
                  <p className='option-percent'>{option.percent}</p>
                  {option.condition &&
                    <p className='option-condition'>{option.condition}</p>
                  }
                </div>
                <img className='option-img' src={option.img} alt="" />
                <p className='option-text'>{option.text}</p>
              </a>
            )
          })
          }
        </div>
      </div>
    </div>
  )
}

export default BuyingOptions