import React, { useEffect, useState } from 'react'
import "./PlanBanner.scss"

import Map from "../../assets/img/village-map.png"
import { getDomain } from '../../functions'


const PlanBanner = () => {

    const [map, setMap] = useState()

    useEffect(() => {
        fetch(`${getDomain()}api/plan/`, {
            method: "GET",
            headers: {},
        })
            .then((response) => {
                if (!response.ok) {
                    console.log(response);
                }
                return response.json();
            })
            .then((data) => {
                setMap(data)
            })
            .catch((error) => console.log(error));
    }, [])

    return (
        <div id="content" className="village-plan">
            <div className="container-center">
                <h2 className="mb-32">План поселка</h2>
            </div>
            <div className="village-plan_map">
                <div className="container-center">
                    <div className="infrastructure">
                        <p className="address">5 км от центра Старого Оскола</p>
                        <p className='comment'>1 очередь строительства</p>
                        <div className="places">
                            <span>Аптеки</span>
                            <span>Сетевые магазины</span>
                            <span>Спортплощадки</span>
                            <span>Школа</span>
                            <span>Детский сад</span>
                        </div>
                    </div>
                    <div className="statuses">
                        <p className="_free">Свободен</p>
                        <p className="_bought">Куплен</p>
                        <p className="_booked">Забронирован</p>
                    </div>
                    <img src={`${getDomain()}${map?.image}`} alt="" className="map-img" />
                </div>
            </div>
        </div>
    )
}

export default PlanBanner