import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import MainLandingPage from "./pages/IndexPage/MainLandingPage";
import HousePage from "./pages/HousePage/HousePage";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<MainLandingPage />} />
      <Route path="house/:id" element={<HousePage/>} />
      <Route path="terms-of-use/" element={<TermsOfUse />} />

    </>
  )
);


function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;
