import React, { useEffect, useState } from 'react'
import MainScreen from '../../Components/MainScreen/MainScreen';
import PlanBanner from '../../Components/PlanBanner/PlanBanner';
import HousesOptions from '../../Components/HousesOptions/HousesOptions';
import BuyingOptions from '../../Components/BuyingOptions/BuyingOptions';
import Advantages from '../../Components/Advantages/Advantages';
import Slider from '../../Components/Slider/Slider';
import Application from '../../Components/Application/Application';
import Contacts from '../../Components/Contacts/Contacts';
import Footer from '../../Components/Footer/Footer';

import "../../styles/App.scss"
import { getDomain } from '../../functions';
import Discount from '../../Components/Discount/Discount';
import Location from '../../Components/Location/Location';

const MainLandingPage = () => {

  const [slider, setSlider] = useState([])

  useEffect(() => {
    fetch(`${getDomain()}api/slider/`)
      .then((response) => {
        if (!response.ok) {
          console.log(response);
        }
        return response.json();
      })
      .then((data) => {
        setSlider([...data])
      })
      .catch((error) => console.log(error));
  }, [])


  return (
    <>
      <MainScreen />

      <PlanBanner />

      <Location />

      <HousesOptions />

      <BuyingOptions />

      {/* <Discount /> */}

      <Advantages />

      <div className="slider-banner">
        <div className="container-center">
          {slider &&
            <Slider data={slider} />

          }
        </div>
      </div>

      <Application />

      <Contacts />

      <Footer />

    </>

  );
}

export default MainLandingPage