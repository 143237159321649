import React, { useRef, useEffect } from 'react'
import { YMaps, Map, Placemark, ZoomControl, useYMaps } from '@pbe/react-yandex-maps';

export default function MyMap() {
    const mapRef = useRef(null);
    const ymaps = useYMaps(['Map']);


    useEffect(() => {
        if (!ymaps || !mapRef.current) {
            return;
        }
        new ymaps.Map(mapRef.current, {
            // center: [51.31487, 37.89462],
            center: [51.31455, 37.89470],
            zoom: 17,
            scrollZoom: false
        })

    }, [ymaps]);

    return (

        <>


            <Map defaultState={{ center: [51.31535, 37.89470], zoom: 17, }}
                instanceRef={ref => { ref && ref.behaviors.disable('scrollZoom'); }}
                // width={'100%'}
                // height={'360rem'}
                className='ya-map'
            > <Placemark geometry={[51.31455, 37.89470]} />
                <ZoomControl />
            </Map>

        </>
    )
}

