import React, { useEffect, useState } from 'react'
import "./HousePage.scss"
import Footer from '../../Components/Footer/Footer'
import { getDomain } from '../../functions'
import Slider from '../../Components/Slider/Slider'
import Modal from '../../Components/Modal/Modal'

const HousePage = () => {

    const url = window.location.pathname
    const [house, setHouse] = useState()
    , [modal, setModal] = useState(false)
    , [modalStyles, setModalStyles] = useState({ display: 'none' })


    useEffect(() => {
        fetch(`${getDomain()}api${url}`, {
            method: "GET",
            headers: {},
        })
            .then((response) => {
                if (!response.ok) {
                    console.log(response);
                }
                return response.json();
            })
            .then((data) => {
                setHouse(data)
            })
            .catch((error) => console.log(error));

    }, [])


  const closeModal = (e = false) => {
    if (e){
         e.preventDefault()
    }
   
    setModal(false)
    setTimeout(() => setModalStyles({ display: 'none' }), 300)
  }
  const openModal = (e) => {
    console.log("123");
    e.preventDefault()
    setModalStyles({ display: 'grid' })
    setTimeout(() => setModal(true), 100)
  }


    return (
        <div className='house-page'>
            <div className='house-page_content'>
                <div className='container-center'>
                    <a className='back-link' href="/">К другим вариантам</a>
                    <div className='about-house'>
                        <div className='house-card'>
                            <div className='house-info'>
                                <div className='d-flex'>
                                    <p className='content-title mr-16'>{house?.title}</p>
                                    <p className='content-area'>{house?.area} м2</p>
                                </div>
                                <div className='house-tags'>
                                    {house?.house_card_rel &&
                                        house.house_card_rel.map((rel, idx) => {
                                            return (
                                                <span className='house-info_rel' key={idx}>{rel.param}</span>
                                            )
                                        })
                                    }
                                </div>
                                <p className='house-description' dangerouslySetInnerHTML={{ __html: house?.description }} />
                            </div>
                            <div onClick={openModal} className='_btn _color'>Забронировать</div>
                        </div>
                        <div className='house-plan'>
                            <img className='plan-img' src={`${getDomain()}${house?.plan_image}`} alt="" />
                            <p className='plan-text'>План</p>
                        </div>
                    </div>
                    {house?.house_slider_rel &&
                        <div className='house-slider'>
                            <Slider data={house.house_slider_rel} />
                        </div>
                    }

                </div>
            </div>
            <Modal active={modal} styles={modalStyles} close={closeModal} ></Modal>
            <Footer />
        </div>
    )
}
export default HousePage