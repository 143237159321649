import React from 'react'
import "./Footer.scss"
import BIK from "../../assets/icons/Logo.svg"
import City from "../../assets/icons/bik-city-logo.svg"

const Footer = () => {
    return (
        <div className='footer'>
            <div className='container-center'>
                <div className='footer-logos'>
                    <a href="https://bik31.ru/"><img className='footer-logo _bik' src={BIK} alt="БИК" /></a>
                    {/* <a href="https://city.bik31.ru/"><img className='footer-logo _bik'  src={City} alt="БИК Сити" /></a> */}
                </div>
                <a href='/terms-of-use/' className='footer-link'>Политика конфиденциальности и обработки персональных данных</a>
            </div>
        </div>
    )
}

export default Footer