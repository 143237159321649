import React, { useState } from 'react'
import "./Modal.scss"
import FormItem from '../Application/FormItem';
import { getDomain, onPhoneInput } from '../../functions'




const Modal = ({ active, classes, styles = {}, close }) => {
    const [formSended, setFormSended] = useState(false);
    const [btnActive, setBtnActive] = useState(false);
    const [data, setData] = useState({});
    const [error, setError] = useState("");

    const fieldHandler = (name, value) => {
        setValue(name, value);
    };

    const setValue = (name, value) => {
        data[name] = value;
        value ?? delete data[name];
        if (value === "") delete data[name];
        setData({ ...data });
        if (
            Object.keys(data).includes("name") &&
            Object.keys(data).includes("phone")
        ) {
            setBtnActive(true);
        } else {
            setBtnActive(false);
        }

    };
    const sendForm = () => {
        setBtnActive(false);
        const formData = new FormData();
        for (const [key, item] of Object.entries(data)) {
            formData.append(key, item);
        }

        fetch(`${getDomain()}api/form/`, {
            method: "POST",
            headers: {},
            body: formData,
        })
            .then((response) => {
                if (!response.ok) {
                    setError("Неверно заполнены поля");
                    throw new Error("Ошибка. Неверно заполнены поля");
                    setBtnActive(true);
                } else {
                    setFormSended(true);
                    close()
                }
                return response.json();
            })
            .catch((error) => {
                console.log("error", error.message);
            });
    };
    return (
        <div className={active ? "modal active" : "modal"} style={styles} onClick={close}>

            <div className={`modal__content ${formSended ? "justify-content-center" : ""}  ${classes ? classes : ''}`} onClick={(e) => e.stopPropagation()}>
                <div>
                    <button className="modal__x-close" onClick={close}></button>
                    {!formSended ?
                        <div className='modal-form'>
                            <p className='modal-form_title'>Отправить заявку</p>
                            <span className="form-error">{error}</span>
                            <form action="" method="POST">
                                <div className="form-container">

                                    <FormItem
                                        type={"text"}
                                        name={"name"}
                                        classes={"_full"}
                                        value={data.name}
                                        fieldHandler={fieldHandler}
                                        label={"ФИО"}
                                        subscrips={"Анонимные обращения не обрабатываются"}
                                    />
                                    <FormItem
                                        type={"phone"}
                                        name={"phone"}
                                        value={data.phone}
                                        fieldHandler={fieldHandler}
                                        placeholder={"+7"}
                                        label={"Телефон"}
                                        mask={onPhoneInput}
                                        setValue={setValue}
                                    />
                                    <label
                                        onClick={(e) => sendForm()}
                                        htmlFor="save_button"
                                        className={`${btnActive ? "" : "disabled"} save_button`}
                                    >
                                        Отправить
                                    </label>
                                </div>


                                <a className='form-subtitle_link' href="/terms-of-use/">Нажимая «Отправить» вы соглашаетесь с политикой обработки персональных данных</a>

                            </form>
                        </div>
                        :
                        <div className="form-success">
                            <p className="form-sended_title">Заявка отправлена </p>
                            <p className="form-sended_subtitle">
                                Наш менеджер свяжется с вами в ближайшее время
                            </p>
                        </div>
                    }

                </div>


            </div>
        </div>
    )
}

export default Modal