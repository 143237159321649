import React, { useEffect, useState } from 'react'
import "./HousesOptions.scss"
import { getDomain } from '../../functions'
import Modal from '../Modal/Modal'


const HousesOptions = () => {

    const [houses, setHouses] = useState()
    , [modal, setModal] = useState(false)
    , [modalStyles, setModalStyles] = useState({ display: 'none' })

    useEffect(() => {
        fetch(`${getDomain()}api/house/`, {
            method: "GET",
            headers: {},
        })
            .then((response) => {
                if (!response.ok) {
                    console.log(response);
                }
                return response.json();
            })
            .then((data) => {
                setHouses(data)
            })
            .catch((error) => console.log(error));
    }, [])


    const closeModal = (e) => {
        e.preventDefault()
        setModal(false)
        setTimeout(() => setModalStyles({ display: 'none' }), 300)
      }
      const openModal = (e) => {
        console.log("123");
        e.preventDefault()
        setModalStyles({ display: 'grid' })
        setTimeout(() => setModal(true), 100)
      }

    return (
        <div id='options' className='houses-options'>
            <div className="container-center">
                <div className='houses-options_grid'>
                    <div>
                        <h2 className='mb-22'>Варианты домов</h2>
                        <p className='houses-options_subtitle'>Самые качественные материалы в домах для дружных семей</p>
                        <p className='houses-options_list'>Ленточный фундамент</p>
                        <p className='houses-options_list'>Стены из блоков «Аэробел» 400 мм</p>
                        {/* <p className='houses-options_list'>Утепление "Короед"</p> */}
                        <p className='houses-options_list'>Мягкая кровля от «Технониколь»</p>
                        <p className='houses-options_list'>Окна ПВХ</p>
                        <p className='houses-options_list'>Радиаторы и теплые полы по всему дому</p>



                        {/* <p className='houses-options_list'>Ленточно-свайный фундамент</p>
                        <p className='houses-options_list'>Стены из газоселикатного блока</p>
                        <p className='houses-options_list'>Пустотные плиты перекрытия</p>
                        <p className='houses-options_list'>Полы бетонные, 100 мм, с утеплением по площади дома</p>
                        <p className='houses-options_list'>Полусухая стяжка пола, 50-70 мм</p>
                        <p className='houses-options_list'>Окна металлопластиковые, соответствующие 2-му этапу энергосбережения с двухкамерным стеклопакетом</p>
                        <p className='houses-options_list'>Внутренняя отделка в ванной комнате выполнена из цементно-песчаного раствора, остальные комнаты из гипспового состава</p>
                        <p className='houses-options_list'>Наружная отделка дома выполнена из декоративной штукатурки</p>
                        <p className='houses-options_list'>Плоская кровля с организованным внешним водостоком</p> */}
                    </div>
                    {houses?.map((house, idx) => {
                        return (
                            <div className='house-card' key={idx}>
                                <div className='house-card_cover'>
                                    <img src={`${getDomain()}${house.main_image}`} className='cover-img' alt="" />
                                </div>
                                <div className='house-card_content'>
                                    <div>
                                        <div className='d-flex mb-24'>
                                            <p className='content-area'>{house.area} м2</p>
                                            <p className='content-title'>{house.title}</p>
                                        </div>
                                        <div className='house-info'>
                                            {house.house_card_rel &&
                                                house.house_card_rel.map((rel, idx) => {
                                                    return (
                                                        <span className='house-info_rel' key={idx}>{rel.param}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className='house-card_buttons'>
                                        <a onClick={openModal} className='_btn _color'>Отправить заявку</a>
                                        <a href={`house/${house.id}/`} className='_btn _border'>Подробнее</a>
                                    </div>
                                </div>

                            </div>
                        )
                    })

                    }

                    <Modal active={modal} styles={modalStyles} close={closeModal} ></Modal>
                </div>
            </div>

        </div>)
}

export default HousesOptions