import React, { useCallback, useEffect, useState } from "react";

function FormItem(props) {

  const setValue = (e) => {
    if ( props.mask) {
      const newValue = props.mask(e.target, () => { });
      props.fieldHandler(props.name, newValue);
    } else {
      props.fieldHandler(props.name, e.target.value)
    }
   
  }


  return (
    <div className={`form__item ${props.classes ? props.classes : ""}`}>
      {/* <span className="error-message">{(props.fieldDirty && props.error) && props.error}</span> */}
      {props.subscrips &&
        <p className="subscrips">{props.subscrips}</p>

      }
      <input
        // autocomplete="off"
        type={props.type}
        name={props.name}
        value={props.value}
        // onChange={(e) => props.fieldHandler(props.name, e.target.value)}
        onChange={setValue}
        // onChange={props.handler ? e => props.handler(e, props.field, props.setValue, props.setError) : props.mask ? e => props.mask(e, props.setValue, props.setError) : e => props.fieldHandler(e)}

        className={`form-field`}
        placeholder={props.placeholder}
        required={true}
      />
      <label className="form-label" htmlFor={props.name}>
        {props.label}
      </label>
    </div>
  );
}

export default FormItem;
