import React, { useState } from 'react'
import "./Application.scss"
import { getDomain, onPhoneInput } from '../../functions'
import FormItem from './FormItem'



const Application = () => {
    const [formSended, setFormSended] = useState(false);
    const [btnActive, setBtnActive] = useState(false);
    const [data, setData] = useState({});
    const [error, setError] = useState("");




    const fieldHandler = (name, value) => {
        setValue(name, value);
    };

    const setValue = (name, value) => {
        data[name] = value;
        value ?? delete data[name];
        if (value === "") delete data[name];
        // console.log("data", data);
        setData({ ...data });
        if (
            Object.keys(data).includes("name") &&
            Object.keys(data).includes("phone")
        ) {
            setBtnActive(true);
        } else {
            setBtnActive(false);
        }
    };
    const sendForm = () => {
        setBtnActive(false);
        const formData = new FormData();
        for (const [key, item] of Object.entries(data)) {
            formData.append(key, item);
        }

        fetch(`${getDomain()}api/form/`, {
            method: "POST",
            headers: {},
            body: formData,
        })
            .then((response) => {
                if (!response.ok) {
                    setError("Неверно заполнены поля");
                    throw new Error("Ошибка. Неверно заполнены поля");
                    setBtnActive(true);
                } else {
                    setFormSended(true);
                }
                return response.json();
            })
            .catch((error) => {
                console.log("error", error.message);
            });
    };

    return (
        <div id='consultation' className='application'>
            <div className='container-center'>
                <h2 className='mb-16'>Заявка</h2>
                <p className='application-subtitle'>Оставьте заявку в форме ниже, чтобы могли связаться с вами и рассказать о проекте подробнее</p>
                <div className='application-block'>
                    {!formSended ? (
                        <div className='consultation-form'>
                            <span className="form-error">{error}</span>
                            <form action="" method="POST">
                                <div className="form-container">

                                    <FormItem
                                        type={"phone"}
                                        name={"phone"}
                                        value={data.phone}
                                        fieldHandler={fieldHandler}
                                        placeholder={"+7"}
                                        label={"Телефон"}
                                        mask={onPhoneInput}
                                        setValue={setValue}
                                    />
                                    <FormItem
                                        type={"text"}
                                        name={"name"}
                                        classes={"_full"}
                                        value={data.name}
                                        fieldHandler={fieldHandler}
                                        label={"ФИО"}
                                    />
                                    <label
                                        onClick={(e) => sendForm()}
                                        htmlFor="save_button"
                                        className={`${btnActive ? "" : "disabled"} save_button`}
                                    >
                                        Отправить заявку
                                    </label>
                                </div>

                                <p className="form-subtitle">
                                    Отправляя заявку, вы соглашаетесь с <a className='form-subtitle_link' href="/terms-of-use/">Политикой конфиденциальности и обработки персональных данных</a>
                                </p>
                            </form>
                        </div>
                    ) : (
                        <div className="form-success">
                            <p className="form-sended_title">Заявка отправлена </p>
                            <p className="form-sended_subtitle">
                                Наш менеджер свяжется с вами в ближайшее время
                            </p>
                        </div>
                    )}
                    <a href='https://t.me/oskol_house' className='tg-application'>
                        <p>Написать в <span>Telegram</span></p>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Application