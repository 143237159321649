import React, { useEffect, useState } from 'react'
import "./TermsOfUse.scss"
import { getDomain } from '../../functions'
import Footer from '../../Components/Footer/Footer'

const TermsOfUse = () => {

    const [policy, setPolicy] = useState()

    useEffect(() => {
        fetch(`${getDomain()}api/policy/`, {
            method: "GET",
            headers: {},
        })
            .then((response) => {
                if (!response.ok) {
                    console.log(response);
                }
                return response.json();
            })
            .then((data) => {
                setPolicy(data)
            })
            .catch((error) => console.log(error));

    }, [])


    console.log(policy);



    return (
        <div className='policy-page'>
            <div className='container-center mb-144'>
                <a className='back-link' href="/">На главную</a>
                <h2>Политика конфиденциальности и обработки персональных данных</h2>
                <span
                    className="policy-text"
                    dangerouslySetInnerHTML={{ __html: policy?.policy }}
                />
            </div>
            <Footer />
        </div>
    )
}

export default TermsOfUse