import React from 'react'
import Logo from "../../assets/icons/Logo.svg"
import BG from "../../assets/img/c133449b06e00ebf8cf1195db01866e9.jpg"
import "./MainScreen.scss"

const MainScreen = () => {
    return (
        <div className="main-screen">
            <div className="main-screen_header">
                <a href="/"><img src={Logo} alt="БИК" /></a>
                <div className="header-buttons">
                    <a className="header-phone" href="tel:+79913155535">+7 991 315 55 35</a>
                    <a className="header-btn _phone" href="tel:+79913155535">Консультация</a>
                    <a className="header-btn _telegram mr-8" href="https://t.me/oskol_house">Написать</a>
                </div>
            </div>
            <div className="main-screen_content">
                <div className="address">
                    <p>Старый Оскол, мкр Пушкарские дачи 2</p>
                </div>
                <div className="title-block">
                    <h1>Дома</h1>
                    <div className='buttons-block'>
                          <a className="title-btn" href="#consultation">Оставить<br />заявку</a>
                    <a className="title-tg" href="https://t.me/oskol_house"></a>
                    </div>
                  
                </div>
                <h2>в Пушкарской даче</h2>
                <div className="village-info">
                    <p className="_house">43 дома</p>
                    <p className="_map">8,9 га земли</p>
                    <p className="_squares">от 100 000 ₽</p>
                </div>
            </div>
            <a href="#content" className="down-arrow"></a>
            <img className='main-screen_bg' src={BG} alt="мкр Пушкарские дачи 2" />
        </div>
    )
}

export default MainScreen