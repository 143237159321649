import React, { useEffect, useState } from 'react'
import "./Slider.scss"
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { getDomain } from '../../functions';


const Slider = ({data}) => {

    let params = {
        modules: [Navigation, Pagination, FreeMode],
        navigation: {
            nextEl: '.GallerySlider__next',
            prevEl: '.GallerySlider__prev',
        },
        // slidesPerGroup: 1,
        // slidesPerView: 1,
        // loop: true,

    };

    return (
        <Swiper {...params} className="content-slider" >
            {data.map((image, idx) => {
                return (
                    <SwiperSlide className='slider-img' key={idx}>
                        <img className='slider-img_img' src={`${getDomain()}${image.image}`} alt={image?.title ? image.title : ''} />
                        {image?.title &&
                            <span className="slider-img_text">{image.title}</span>
                        }
                    </SwiperSlide>
                )
            })}
            <div className="slider-tools">
                <span className='GallerySlider__prev'></span>
                <span className='GallerySlider__next'></span>
            </div>

        </Swiper>
    )
}

export default Slider