import React, { useRef, useEffect } from 'react'
import { YMaps, Map, Placemark, ZoomControl, useYMaps } from '@pbe/react-yandex-maps';

export default function MyMap() {
    const mapRef = useRef(null);
    const ymaps = useYMaps(['Map']);


    useEffect(() => {
        if (!ymaps || !mapRef.current) {
            return;
        }
        new ymaps.Map(mapRef.current, {
            center: [51.326811921211906, 37.967853099394304],
            zoom: 14,
            scrollZoom: false
        })

    }, [ymaps]);

    return (

        <>


            <Map defaultState={{ center: [51.326811921211906, 37.967853099394304], zoom: 14, }}
                instanceRef={ref => { ref && ref.behaviors.disable('scrollZoom'); }}
                // width={'100%'}
                // height={'360rem'}
                className='ya-map'
            > <Placemark geometry={[51.326811921211906, 37.967853099394304]} />
                <ZoomControl />
            </Map>

        </>
    )
}

