import React from 'react'
import "./Location.scss"
import MyMap from "./Map";
import { YMaps } from '@pbe/react-yandex-maps';





const Location = () => {
    return (
        <div className='location'>
            <div className='container-center'>
                <h2 className='mb-24'>Расположение</h2>
            </div>
            <div className='yandex-map'>
                <YMaps>
                    <MyMap />
                </YMaps>
            </div>
        </div>
    )
}

export default Location